import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';

import creatorLove1 from '../../../assets/photos/creatore-love1.png';
import creatorLove2 from '../../../assets/photos/creatore-love2.png';
import creatorLove3 from '../../../assets/photos/creatore-love3.png';
import creatorLove4 from '../../../assets/photos/creatore-love4.png';
import leftArrow from '../../../assets/photos/prev-white-arrow.png';
import rightArrow from '../../../assets/photos/next-white-arrow.png';

import css from './SectionCreatorLove.module.css';

const SectionCreatorLove = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const customDotStyle = {
    backgroundColor: '#fff',
    opacity: 0.5,
    height: '9px',
    margin: '0 3px',
    display: 'inline-block',
    borderRadius: '45px',
    transition: 'width 0.3s ease-in-out', // Smooth transition for width changes
  };

  const renderIndicator = (onClickHandler, isSelected, index, label) => (
    <li
      className={isSelected ? css.activeDot : css.inactiveDot}
      onClick={onClickHandler}
      onKeyDown={event => {
        if (event.key === 'Enter' || event.key === ' ') {
          event.preventDefault();
          onClickHandler();
        }
      }}
      value={index}
      role="button"
      tabIndex={0}
      aria-label={`${label} ${index + 1}`}
      style={{
        ...customDotStyle,
        width: isSelected ? '100px' : '24px', // Dynamic width adjustment
        opacity: isSelected ? 1 : 0.5, // Highlight selected dot
      }}
    />
  );

  const handleChange = index => {
    setCurrentIndex(index);
  };
  const text = [
    {
      heading: 'Alex J.',
      label: 'Freelance Photographer & Content Creator',
      image: creatorLove1,
      text: (
        <p className={css.text}>
          "Joining ukreate.com was the best decision for my career. I've connected with amazing
          brands and increased my income significantly through exciting projects."
        </p>
      ),
    },
    {
      heading: 'Jasmine T.',
      label: 'Lifestyle Blogger & Influencer',
      image: creatorLove2,
      text: (
        <p className={css.text}>
          "ukreate.com's platform is so easy to use. I've built a professional portfolio, joined a
          supportive community, and found partnerships that align perfectly with my style."
        </p>
      ),
    },
    {
      heading: 'Maya S.',
      label: 'Yoga Instructor & Wellness Influencer',
      image: creatorLove3,
      text: (
        <p className={css.text}>
          "With ukreate, I've partnered with brands that share my passion for holistic living. The
          collaborations feel authentic, and the seamless communication tools make the process
          effortless. Being part of this community has not only expanded my professional network but
          also enriched my personal growth."
        </p>
      ),
    },
    {
      heading: 'Mark H.',
      label: 'Food Blogger',
      image: creatorLove4,
      text: (
        <p className={css.text}>
          "ukreate.com was the best decision for my career. I've connected with amazing brands and
          increased my income significantly through exciting projects."
        </p>
      ),
    },
  ];
  const renderArrowPrev = (onClickHandler, hasPrev, label) => (
    <button
      type="button"
      onClick={onClickHandler}
      className={css.leftArrow}
      aria-label={label}
      disabled={!hasPrev}
    >
      <img src={leftArrow} alt="Previous" className={css.arrow} />
    </button>
  );

  const renderArrowNext = (onClickHandler, hasNext, label) => (
    <button
      type="button"
      onClick={onClickHandler}
      className={css.rightArrow}
      aria-label={label}
      disabled={!hasNext}
    >
      <img src={rightArrow} alt="Next" className={css.arrow} />
    </button>
  );

  return (
    <div className={css.creatorLoveWrap}>
      <div className={css.container}>
        <div className={css.creatorLoveContainer}>
          <div className={css.creatorLoveTopCont}>
            <h2 className={css.creatorLoveHeading}>Creators Love Us</h2>
          </div>
          <Carousel
            autoPlay={true}
            showStatus={false}
            showThumbs={false}
            showArrows={true}
            showIndicators={true}
            infiniteLoop={true}
            swipeable={true}
            transitionTime={2000}
            interval={5000}
            onChange={handleChange}
            renderArrowPrev={renderArrowPrev}
            renderArrowNext={renderArrowNext}
            renderIndicator={renderIndicator}
            centerMode={true}
            centerSlidePercentage={33.33}
            className={css.finalSlider}
          >
            {text.map((item, index) => (
              <div className={css.creatorLoveSlider} key={index}>
                <div className={css.creatorSliderCont}>
                  <div className={css.creatorSlideImg}>
                    <img src={item.image} alt="creatorSlideImg" />
                  </div>
                  <div className={css.creatorAuthWrap}>
                    <div className={css.creatorSlideContWrap}>
                      <h3 className={css.creatorSlideHead}>{item.heading}</h3>
                      <p className={css.creatorSlideLabel}>{item.label}</p>
                    </div>
                    {item.text}
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default SectionCreatorLove;
