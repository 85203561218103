import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { isScrollingDisabled } from '../../ducks/ui.duck';

import { Page } from '../../components';

import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';

import SectionHeroNew from './SectionHeroNew/SectionHeroNew';
import SectionBrandList from './SectionBrandList/SectionBrandList';
import SectionRightPartner from './SectionRightPartner/SectionRightPartner';
import SectionRewardCollab from './SectionRewardCollab/SectionRewardCollab';
import SectionBrandLove from './SectionBrandLove/SectionBrandLove';
import SectionCreatorLove from './SectionCreatorLove/SectionCreatorLove';
import SectionLearnGrow from './SectionLearnGrow/SectionLearnGrow';
import SectionCreatorEco from './SectionCreatorEco/SectionCreatorEco';
import SectionShowCase from './SectionShowCase/SectionShowCase';
import SectionBrandLoveMob from './SectionBrandLoveMob/SectionBrandLoveMob';
import SectionBrandListMob from './SectionBrandListMob/SectionBrandListMob';
import SectionCreatorLoveMob from './SectionCreatorLoveMob/SectionCreatorLoveMob';
import SectionCreatorEcoMob from './SectionCreatorEcoMob/SectionCreatorEcoMob';

import './carousel.css';

import css from './UkrateLandingPage.module.css';

/**
 * @param {Object} props
 * @param {Object} props.currentUser
 * @returns {React.ReactNode}
 */
export const UkrateLandingPageComponent = props => {
  const { currentUser, scrollingDisabled } = props;

  return (
    <Page scrollingDisabled={scrollingDisabled}>
      <TopbarContainer
        mobileRootClassName={css.mobileTopbar}
        desktopClassName={css.desktopTopbar}
        mobileClassName={css.mobileTopbar}
      />
      <div className={css.root}>
        <SectionHeroNew currentUser={currentUser} />
        <SectionBrandList currentUser={currentUser} />
        <SectionBrandListMob />
        <SectionRightPartner currentUser={currentUser} />
        <SectionRewardCollab />
        <SectionBrandLove />
        <SectionBrandLoveMob />
        <SectionCreatorLove />
        <SectionCreatorLoveMob />
        <SectionLearnGrow currentUser={currentUser} />
        <SectionCreatorEco />
        <SectionCreatorEcoMob />
        <SectionShowCase currentUser={currentUser} />
      </div>
      <FooterContainer />
    </Page>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return { currentUser, scrollingDisabled: isScrollingDisabled(state) };
};

const UkrateLandingPage = compose(connect(mapStateToProps))(UkrateLandingPageComponent);

export default UkrateLandingPage;
