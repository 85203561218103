import React from 'react';

import PartnerIcon1 from '../../../assets/photos/partner-icon1.svg';
import PartnerIcon2 from '../../../assets/photos/partner-icon2.svg';
import PartnerIcon3 from '../../../assets/photos/partner-icon3.svg';
import PartnerIcon4 from '../../../assets/photos/partner-icon4.svg';
import PartnerRightImg from '../../../assets/photos/partner-right-img.png';

import css from './SectionRightPartner.module.css';

const SectionRightPartner = ({ currentUser }) => {
  return (
    <div className={css.root}>
      <div className={css.container}>
        <div className={css.topHeadingWrap}>
          <h2 className={css.heading}>
            Connect. Collaborate. Create.
            <br /> Discover ukreate's Benefits
          </h2>
        </div>
        <div className={css.partnerIconContWrap}>
          <div className={css.partnerContWrap}>
            <span className={css.partnerLabel}>For Brands & Agencies</span>
            <h2 className={css.partnerHeading}>Meet the Right Creator</h2>
            <div className={css.partnerIconCont}>
              <div className={css.partnerIconWrap}>
                <img src={PartnerIcon1} alt="partner-icon" />
              </div>
              <div className={css.partnerContent}>
                <h3 className={css.partnerInnerHeading}>Discover Creators Who Get Your Vision</h3>
                <p className={css.partnerText}>
                  Connect with creators perfectly aligned with your brand's message.
                </p>
              </div>
            </div>
            <div className={css.partnerIconCont}>
              <div className={css.partnerIconWrap}>
                <img src={PartnerIcon2} alt="partner-icon" />
              </div>
              <div className={css.partnerContent}>
                <h3 className={css.partnerInnerHeading}>Collaborate Without the Hassle</h3>
                <p className={css.partnerText}>
                  Manage communications, projects, and assets seamlessly.
                </p>
              </div>
            </div>
            <div className={css.partnerIconCont}>
              <div className={css.partnerIconWrap}>
                <img src={PartnerIcon3} alt="partner-icon" />
              </div>
              <div className={css.partnerContent}>
                <h3 className={css.partnerInnerHeading}>Secure Transactions, Guaranteed</h3>
                <p className={css.partnerText}>
                  Handle transactions confidently with our trusted payment system.
                </p>
              </div>
            </div>
            <div className={css.partnerIconCont}>
              <div className={css.partnerIconWrap}>
                <img src={PartnerIcon4} alt="partner-icon" />
              </div>
              <div className={css.partnerContent}>
                <h3 className={css.partnerInnerHeading}>Dedicated Support for Your Success</h3>
                <p className={css.partnerText}>
                  Leverage our team's expertise to maximize campaign impact.
                </p>
              </div>
            </div>
          </div>
          <div className={css.partnerRightImg}>
            <img
              className={css.partnerRightImgFinal}
              src={PartnerRightImg}
              alt="partner-right-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionRightPartner;
