import React from 'react';
import { Link } from 'react-router-dom';

import showCase1 from '../../../assets/photos/show_case1.png';
import showCase2 from '../../../assets/photos/show_case2.png';
import buttonIcon1 from '../../../assets/photos/find-creatorr.png';
import buttonIcon2 from '../../../assets/photos/signup-icon.png';

import css from './SectionShowCase.module.css';

const SectionShowCase = ({ currentUser }) => {
  const { attributes } = currentUser || {};
  const { profile } = attributes || {};
  const { publicData } = profile || {};
  const { userType } = publicData || {};
  const isBrand = userType === 'brand';
  var data = [
    {
      title: 'Ready to Amplify Your Brand?',
      content: 'Connect with top creators and elevate your campaigns.',
      buttonText: 'Find your perfect creator',
      buttonIcon: buttonIcon1,
      image: showCase1,
    },
    {
      title: 'Ready to Showcase Your Creativity?',
      content: 'Collaborate with leading brands and grow your portfolio.',
      buttonText: 'Sign up to be a creator',
      buttonIcon: buttonIcon2,
      image: showCase2,
    },
  ];

  return (
    <div className={css.root}>
      <div className={css.container}>
        <div className={css.showCaseWrap}>
          {data.map((item, index) => {
            return (
              <div
                className={css.showCaseCard}
                style={{ backgroundColor: index == 0 ? '#500543' : '#9A4D0D', color: '#fff' }}
                key={index}
              >
                <div className={css.showCaseCont}>
                  <h2 className={css.showCaseTitle}>{item.title}</h2>
                  <p className={css.showCaseContent}>{item.content}</p>
                </div>
                <div className={css.showCaseButtonWrap}>
                  <Link className={css.showCaseButton} to={index == 0 ? '/s' : '/signup'}>
                    <span className={css.showCaseButtonIcon}>
                      <img src={item.buttonIcon} alt="btn-icon" />
                    </span>{' '}
                    {item.buttonText}
                  </Link>
                </div>
                <div className={css.showCaseImage}>
                  <img className={css.showCaseImageFinal} src={item.image} alt="showcase-img" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SectionShowCase;
